<template>
  <AwlCard v-bind="{...$attrs, ...$props}" class="awl-user-card">
      <template #title>
        <span class="d-block">{{ user.name }} {{ user.surname }}</span>
        <small class="user-role text-primary">{{ userRole }}</small>
        <hr>
        <p class="pb-3 m-0 small text-muted user-email">
          {{ user.email }}
        </p>
    </template>
    <template #default>
      <slot name="footer">
        <div class="card-footer d-flex align-items-center justify-content-between">
          <router-link :to="{ name: 'users.edit', params: { id: user.id } }" class="btn btn-sm btn-secondary edit-btn">Modifica</router-link>
          <router-link :to="{ name: 'users.show', params: { id: user.id } }" class="btn btn-sm btn-primary view-btn">Visualizza</router-link>
        </div>
      </slot>
    </template>
  </AwlCard>
</template>

<script>
export default {
  components: {
    AwlCard: () => import('./AwlCard'),
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userRole () {
      return this.user.role === 'operator' ? this.user?.companyName : 'Amministratore';
    },
  },
};
</script>

<style lang="scss">
.awl-user-card {
  overflow: hidden;
  border: 1px solid rgba(33, 37, 41, 0.075)!important;
  .awl-card--header {
    padding: 1rem !important;
    padding-bottom: 0 !important;
    .user-role {
      font-size: 12px;
    }
  }
  .awl-card--title {
      color: $dark !important;
      text-transform: initial !important;
      font-size: 1.5rem;
      line-height: 1.25rem;
      margin-bottom: 0;
    }
  .awl-card--content {
    padding: 0 1rem 1rem !important;
    background: rgba(33, 37, 41, 0.075);
  }
  hr {
    background: $light;
    border: none;
    height: 1px;
  }
  .card-footer {
    padding-top: 1rem;
  }

  .edit-btn {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .04rem;
    border: 0;
    font-size: 12px;
  }

  .view-btn {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .04rem;
    border: 0;
    font-size: 12px;
  }

  .user-email {
    font-size: 16px;
  }
}
</style>
